import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";
import { evoContractAddress as contractAddress, setApprovalForAll } from ".";

const abi = [
  {
    inputs: [
      { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
    ],
    name: "evolveNBulls",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const evolveBOTB = async (tokenIds = []) => {
  if (!tokenIds.length) {
    console.error("No tokenIds provided");
  }
  const walletAddress = useWeb3Store().walletAddress;
  if (!walletAddress) {
    console.error("No walletAddress found");
    return;
  }

  const approved = await setApprovalForAll();

  if (!approved) {
    console.error("Not approved");
    return;
  }

  // const ids = tokenIds.map((el) => el.toString());

  const contract = new web3.eth.Contract(abi, contractAddress);
  const gas = await contract.methods
    .evolveNBulls(tokenIds)
    .estimateGas({ from: walletAddress });

  await useToastTransaction(contract.methods.evolveNBulls(tokenIds), {
    text: "Evolving OG Bulls",
    successText: `Success! You evolved ${tokenIds.length} OG ${
      tokenIds.length > 1 ? "Bulls" : "Bull"
    }`,
    sendOptions: { gas, from: walletAddress },
  });
};

export default evolveBOTB;
