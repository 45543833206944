import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { web3 } from "@/web3";

const { useWeb3Store } = require("@/store/web3");

const claimBOTB = async function (claims) {
  const walletAddress = useWeb3Store().walletAddress;
  const abi = [
    {
      inputs: [
        {
          internalType: "uint256[]",
          name: "tokenIds",
          type: "uint256[]",
        },
      ],
      name: "claimNBulls",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];
  const contract = new web3.eth.Contract(
    abi,
    "0x5221B7FE10506a4341D402851FD44919D7B11255"
  );

  const ids = claims.map((el) => el.toString());
  const gas = await contract.methods
    .claimNBulls(ids)
    .estimateGas({ from: walletAddress });

  await useToastTransaction(contract.methods.claimNBulls(ids), {
    text: "Unvaulting Bulls",
    successText: `Success! You unvaulted ${ids.length} Bulls`,
    sendOptions: { gas, from: walletAddress },
  });
};

export default claimBOTB;
