import getNFTOwner from "../getNFTOwner";

const canEvoClaimed = async (tokenId) => {
  const ogContractAddress = "0x5221b7fe10506a4341d402851fd44919d7b11255";

  try {
    const owner = await getNFTOwner({
      tokenId: tokenId,
      contractAddress: "0x3a8778A58993bA4B941f85684D74750043A4bB5f",
    });

    // check if owner is the OG contract
    if (owner.toLowerCase() !== ogContractAddress.toLowerCase()) return false;
    return true;
  } catch (error) {
    console.error(error);
  }
};

export default canEvoClaimed;
