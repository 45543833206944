const ogContractAddress = "0x3a8778A58993bA4B941f85684D74750043A4bB5f";
const evoContractAddress = "0x6069a6C32cf691F5982FEbAe4fAf8a6f3AB2F0F6";
const bearsContractAddress = "0x02AA731631c6D7F8241d74F906f5b51724Ab98F8";

import { setApprovalForAll, isApprovalForAll } from "./bullsApproval";
import evolveBOTB from "./evolveBOTB";
import mintBear from "./mintBear";
import getAllBOTBBulls from "./getAllBOTBBulls";
import claimBOTB from "./claimBOTB";
import isOgEvolved from "./isOgEvolved";
import isBearMinted from "./isBearMinted";
import canEvoClaimed from "./canEvoClaimed";

export {
  ogContractAddress,
  evoContractAddress,
  bearsContractAddress,
  setApprovalForAll,
  isApprovalForAll,
  evolveBOTB,
  mintBear,
  getAllBOTBBulls,
  claimBOTB,
  isOgEvolved,
  canEvoClaimed,
  isBearMinted,
};
