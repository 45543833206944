<template>
  <div v-if="!isConnected">
    <button
      v-if="!isWalletConnecting"
      class="claim-botb__button"
      :disabled="isWalletConnecting"
      @click="connect"
    >
      {{ isWalletConnecting ? "Connecting..." : "Connect Wallet" }}
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useWeb3Store } from "@/store/web3";
import { connectWallet } from "@/web3";

const web3Store = useWeb3Store();

const isConnected = computed(() => {
  return web3Store.isConnected;
});
const isWalletConnecting = ref(false);

const connect = async () => {
  isWalletConnecting.value = true;
  await connectWallet();
  isWalletConnecting.value = false;
};
</script>
