import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";
import { evoContractAddress, ogContractAddress } from ".";

const abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const setApprovalForAll = async () => {
  const contract = new web3.eth.Contract(abi, ogContractAddress);
  const walletAddress = useWeb3Store().walletAddress;
  if (!walletAddress) {
    console.error("No walletAddress found");
    return false;
  }

  const isApproved = await contract.methods
    .isApprovedForAll(walletAddress, evoContractAddress)
    .call();

  if (isApproved) {
    console.log("Already approved");
    return true;
  }

  try {
    await useToastTransaction(
      contract.methods.setApprovalForAll(evoContractAddress, true),
      {
        text: "Approving EVO Contract",
        successText: `Success! You approved the EVO Contract`,
        sendOptions: { from: walletAddress },
      }
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const isApprovalForAll = async () => {
  const contract = new web3.eth.Contract(abi, ogContractAddress);

  const walletAddress = useWeb3Store().walletAddress;
  if (!walletAddress) {
    console.error("No walletAddress found");
    return;
  }

  const isApproved = await contract.methods
    .isApprovedForAll(walletAddress, evoContractAddress)
    .call();

  return isApproved;
};
