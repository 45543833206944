<template>
  <main class="claim-botb">
    <h1 class="claim-botb__title">
      BOTB
    </h1>
    <BOTBChecker />
    <ConnectWallet />
    <div v-if="isCollectionLoading">
      <img
        class="loader"
        src="@/assets/img/spinner-clear.svg"
        width="86"
      >
    </div>
    <div v-if="!isCollectionLoading && isConnected">
      <BOTBNav
        :selected-tab="selectedTab"
        :handle-change-tab="handleChangeTab"
        :functional-row="true"
      />
      <button
        v-if="selectedCollectionAction.show"
        class="claim-botb__button"
        :disabled="collectionActionLoading"
        @click="selectedCollectionAction.action"
      >
        {{
          collectionActionLoading ? "Loading..." : selectedCollectionAction.text
        }}
      </button>
      <div class="claim-botb__cards">
        <TokenCard
          v-for="token in selectedCollection || []"
          :id="token.id"
          :key="token.id"
          :image="token.image"
          :claimable="token.isClaimable"
          :mintable="token.mintable"
          :evolvable="token.evolvable"
        />
      </div>
      <div
        v-if="selectedCollection?.length === 0"
        class="claim-botb__no-tokens"
      >
        <p>No {{ selectedTabName }} found in your wallet.</p>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import ConnectWallet from "@/components/BOTB/ConnectWallet.vue";
import { useWeb3Store } from "@/store/web3";
import {
  claimBOTB,
  evolveBOTB,
  getAllBOTBBulls,
  mintBear,
} from "@/functions/botb";
import BOTBNav from "@/components/BOTB/BOTBNav.vue";
import TokenCard from "@/components/BOTB/TokenCard.vue";
import BOTBChecker from "@/components/BOTB/BOTBChecker.vue";

const web3Store = useWeb3Store();
const isConnected = computed(() => web3Store.isConnected);
const collection = ref({
  bearTokens: [],
  evoTokens: [],
  ogTokens: [],
});
const isCollectionLoading = ref(false);

const selectedTab = ref("claimable");
const selectedTabName = ref("Unvaultable Tokens");

const handleChangeTab = (tab) => {
  selectedTab.value = tab;
  switch (tab) {
    case "bearTokens":
      selectedTabName.value = "BOTB Bear";
      break;
    case "evoTokens":
      selectedTabName.value = "BOTB EVO Bull";
      break;
    case "ogTokens":
      selectedTabName.value = "BOTB OG Bull";
      break;
    case "claimable":
      selectedTabName.value = "Unvaultable Tokens";
      break;
    case "mintable":
      selectedTabName.value = "Mintable Tokens";
      break;
    case "evolvable":
      selectedTabName.value = "Evolvable Tokens";
      break;
    default:
      selectedTabName.value = "";
  }
};

watch(
  () => web3Store.isConnected,
  async (isConnected) => {
    if (isConnected) {
      try {
        isCollectionLoading.value = true;
        const allCollection = await getAllBOTBBulls();
        collection.value = allCollection;
      } catch (error) {
        collection.value = null;
      } finally {
        isCollectionLoading.value = false;
      }
    } else {
      collection.value = {
        bearTokens: [],
        evoTokens: [],
        ogTokens: [],
      };
    }
  }
);

const selectedCollection = computed(() => {
  if (selectedTab.value === "claimable") {
    return collection.value.evoTokens.filter((t) => t.isClaimable);
  }
  if (selectedTab.value === "mintable") {
    return collection.value.ogTokens.filter((t) => t.mintable);
  }
  if (selectedTab.value === "evolvable") {
    return collection.value.ogTokens.filter((t) => t.evolvable);
  }
  return collection.value[selectedTab.value];
});

const collectionActionLoading = ref(false);

const selectedCollectionAction = computed(() => {
  let text = "";
  let action = async () => {};
  let show = false;
  if (selectedTab.value === "claimable") {
    text = "Unvault All";
    const ids = collection.value.evoTokens
      .filter((t) => t.isClaimable)
      .map((t) => t.id);

    show = ids.length > 1;

    action = async () => claimBOTB(ids);
  }
  if (selectedTab.value === "mintable") {
    text = "Mint All";
    // get first 50 mintable tokens
    let ids = collection.value.ogTokens
      .filter((t) => t.mintable)
      .map((t) => t.id);

    if (ids.length > 50) {
      ids = ids.slice(0, 50);
    }
    show = ids.length > 1;
    action = async () => {
      mintBear(ids);
    };
  }
  if (selectedTab.value === "evolvable") {
    text = "Evolve All";
    // get first 20 evolvable tokens
    let ids = collection.value.ogTokens
      .filter((t) => t.evolvable)
      .map((t) => t.id);

    if (ids.length > 20) {
      ids = ids.slice(0, 20);
    }
    show = ids.length > 1;
    action = async () => {
      evolveBOTB(ids);
    };
  }
  return {
    show: show,
    text: text,
    action: async () => {
      collectionActionLoading.value = true;
      await action();
      collectionActionLoading.value = false;
    },
  };
});
</script>
