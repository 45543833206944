import { useBapApi } from "@/api/useBapApi";
import { useToast } from "@/composables/useToast";
import { useWeb3Store } from "@/store/web3";
import { ref } from "vue";
import canEvoClaimed from "./canEvoClaimed";
import isBearMinted from "./isBearMinted";
import isOgEvolved from "./isOgEvolved";

/**
 * returns all the bulls in the BOTB
 * @returns {Promise} - resolves to an Obj of all the bulls in the BOTB
 */
const getAllBOTBBulls = async () => {
  const wallet = useWeb3Store().walletAddress;
  if (!wallet) throw new Error("Wallet not connected");

  const collection = ref({
    ogTokens: [],
    evoTokens: [],
    bearTokens: [],
  });

  try {
    const { success, result } = await useBapApi({ throw: true }).exec({
      url: `/mint/botb`,
      params: {
        wallet,
      },
    });

    if (!success) {
      useToast().error({ title: "Error", message: "Something went wrong" });
      return;
    }
    collection.value.ogTokens = [...result.ogTokens];
    collection.value.evoTokens = [...result.evoTokens].map((token) => ({
      ...token,
      isClaimable: ![...result.ogTokens].some((t) => t.id === token.id),
    }));
    collection.value.bearTokens = [...result.bearTokens];

    for (let i = 0; i < collection.value.evoTokens.length; i++) {
      const token = collection.value.evoTokens[i];
      if (token.isClaimable) {
        const canClaim = await canEvoClaimed(token.id);
        if (canClaim) {
          collection.value.evoTokens[i].isClaimable = true;
        } else {
          collection.value.evoTokens[i].isClaimable = false;
        }
      }
    }

    for (let i = 0; i < collection.value.ogTokens.length; i++) {
      const token = collection.value.ogTokens[i];
      const [isMinted, isEvolved] = await Promise.all([
        isBearMinted(token.id),
        isOgEvolved(token.id),
      ]).then((res) => res);

      if (isMinted) {
        collection.value.ogTokens[i].mintable = false;
      } else {
        collection.value.ogTokens[i].mintable = true;
      }

      if (isEvolved) {
        collection.value.ogTokens[i].evolvable = false;
      } else {
        collection.value.ogTokens[i].evolvable = true;
      }
    }

    console.log({
      collection: collection.value,
    });
  } catch (error) {
    console.error(error);
  }

  return collection.value;
};

export default getAllBOTBBulls;
