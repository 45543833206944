import Web3 from "web3";
import { evoContractAddress as contractAddress } from ".";

const infuraKey = process.env.VUE_APP_INFURA_KEY;
const infuraMainnetProvider = `https://mainnet.infura.io/v3/${infuraKey}`;

const abi = [
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "isEvolved",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
];

const isOgEvolved = async (tokenId) => {
  const provider = new Web3.providers.HttpProvider(infuraMainnetProvider);
  const Web3Instance = new Web3(provider);

  const contract = new Web3Instance.eth.Contract(abi, contractAddress);

  let isEvolved = false;

  try {
    isEvolved = await contract.methods.isEvolved(tokenId).call();
  } catch (error) {
    console.error(error);
  }

  return isEvolved;
};

export default isOgEvolved;
