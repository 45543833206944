import { bearsContractAddress as contractAddress } from ".";
import Web3 from "web3";

const infuraKey = process.env.VUE_APP_INFURA_KEY;
const infuraMainnetProvider = `https://mainnet.infura.io/v3/${infuraKey}`;

const abi = [
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "isMinted",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
];

const isBearMinted = async (bearId) => {
  const provider = new Web3.providers.HttpProvider(infuraMainnetProvider);
  const Web3Instance = new Web3(provider);

  const contract = new Web3Instance.eth.Contract(abi, contractAddress);

  let isMinted = false;

  try {
    isMinted = await contract.methods.isMinted(bearId).call();
  } catch (error) {
    console.error(error);
  }

  return isMinted;
};

export default isBearMinted;
