import axios from "axios";
import { useContract } from "@/composables/useContract";

const ownerOfABI = [
  {
    constant: true,
    inputs: [{ name: "_tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

const AlchemyApiKey = "2lMxcIrpntmXsxZeMwgS3js0K5uqbDsn";

const getNFTOwner = async ({ tokenId, contractAddress }) => {
  const nftContract = useContract(ownerOfABI, contractAddress)(false);

  const owner = await nftContract.methods.ownerOf(tokenId).call();

  return owner;
  // if (!AlchemyApiKey) throw new Error("No Alchemy API Key found");
  // if (!tokenId) throw new Error("No Token ID found");
  // if (!contractAddress) throw new Error("No Contract Address found");

  // const url = `https://eth-mainnet.g.alchemy.com/nft/v2/${AlchemyApiKey}/getOwnersForToken?contractAddress=${contractAddress}&tokenId=${tokenId}`;

  // const { data } = await axios.get(url);
  // const owner = data?.owners[0];
  // if (!owner) throw new Error("No owner found");
  // console.log({ owner });
  // return owner;
};

export default getNFTOwner;
