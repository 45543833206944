<template>
  <div class="claim-botb__card">
    <p class="claim-botb__card__title">
      #{{ id }}
    </p>
    <div class="claim-botb__card__img">
      <img
        :src="image"
        alt="nft"
      >
    </div>
    <button
      v-if="claimable"
      :disabled="unvaulting"
      class="claim-botb__card__button"
      @click="claimBull"
    >
      {{ unvaulting ? "Unvaulting..." : "Unvault" }}
    </button>
    <button
      v-if="evolvable"
      :disabled="evolving"
      class="claim-botb__card__button"
      @click="evolveBull"
    >
      {{ evolving ? "evolving..." : "Evolve" }}
    </button>
    <button
      v-if="mintable"
      :disabled="minting"
      class="claim-botb__card__button"
      @click="mintBull"
    >
      {{ minting ? "minting..." : "Mint" }}
    </button>
  </div>
</template>

<script>
import { useToast } from "@/composables/useToast";
import { claimBOTB, evolveBOTB, mintBear } from "@/functions/botb";
import { ref } from "vue";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    claimable: {
      type: Boolean,
      required: true,
      default: false,
    },
    evolvable: {
      type: Boolean,
      required: true,
      default: false,
    },
    mintable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const unvaulting = ref(false);
    const minting = ref(false);
    const evolving = ref(false);
    const claimBull = async () => {
      unvaulting.value = true;
      try {
        await claimBOTB([props.id]);
      } catch (error) {
        console.error(error.message.split("{")[0]);
        useToast().error({ title: error.message.split("{")[0] });
      } finally {
        unvaulting.value = false;
      }
    };

    const mintBull = async () => {
      minting.value = true;
      try {
        await mintBear([props.id]);
      } catch (error) {
        console.error(error.message.split("{")[0]);
        useToast().error({ title: error.message.split("{")[0] });
      } finally {
        minting.value = false;
      }
    };

    const evolveBull = async () => {
      evolving.value = true;
      try {
        await evolveBOTB([props.id]);
      } catch (error) {
        console.error(error.message.split("{")[0]);
        useToast().error({ title: error.message.split("{")[0] });
      } finally {
        evolving.value = false;
      }
    };
    return {
      claimBull,
      unvaulting,
      minting,
      evolving,
      mintBull,
      evolveBull,
    };
  },
};
</script>
