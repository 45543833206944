import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";
import { bearsContractAddress as contractAddress } from ".";

const abi = [
  {
    inputs: [
      { internalType: "uint256[]", name: "tokenIds", type: "uint256[]" },
    ],
    name: "mintNBears",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const mintBear = async (bearIDs = []) => {
  if (!bearIDs.length) {
    console.error("No bearIDs provided");
  }

  const walletAddress = useWeb3Store().walletAddress;

  if (!walletAddress) {
    console.error("No walletAddress found");
    return;
  }

  const ids = bearIDs.map((el) => el.toString());

  const contract = new web3.eth.Contract(abi, contractAddress);

  const gas = await contract.methods
    .mintNBears(ids)
    .estimateGas({ from: walletAddress });

  await useToastTransaction(contract.methods.mintNBears(ids), {
    text: "Minting Bears",
    successText: `Success! You minted ${ids.length} ${
      ids.length > 1 ? "Bears" : "Bear"
    }`,
    sendOptions: { gas, from: walletAddress },
  });
};

export default mintBear;
