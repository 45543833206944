<template>
  <div class="claim-botb__checker">
    <hgroup class="claim-botb__checker-header">
      <h2>Mint Checker</h2>
      <p>Check if BOTB Bear/EVO is eligible to be minted.</p>
    </hgroup>
    <div class="claim-botb__checker-inputs">
      <input
        v-model="inputState.ogId"
        type="number"
        placeholder="Enter BOTB OG ID"
        :disabled="inputState.loading"
      >
      <button
        :disabled="inputState.loading"
        @click="checkEligibility"
      >
        {{ inputState.loading ? "Checking..." : "Check" }}
      </button>
    </div>
    <p
      v-if="typeof checkBear.isEligible === 'boolean'"
      :class="`claim-botb__checker-result ${
        checkBear.isEligible ? 'success' : 'error'
      }`"
    >
      BOTB Bear {{ checkBear.isEligible ? "Eligible" : "Not Eligible" }} to be
      minted.
    </p>
    <p
      v-if="typeof checkEvo.isEligible === 'boolean'"
      :class="`claim-botb__checker-result ${
        checkEvo.isEligible ? 'success' : 'error'
      }`"
    >
      BOTB Evo {{ checkEvo.isEligible ? "Eligible" : "Not Eligible" }} to be
      minted.
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { isBearMinted, isOgEvolved } from "@/functions/botb";

const inputState = ref({
  ogId: "",
  loading: false,
  success: false,
  error: "",
});

const checkBear = ref({
  isEligible: undefined,
});

const checkEvo = ref({
  isEligible: undefined,
});

const checkEligibility = async () => {
  if (inputState.value.ogId === "") return;

  try {
    inputState.value.loading = true;
    const [bearMinted, isEvoMinted] = await Promise.all([
      isBearMinted(inputState.value.ogId),
      isOgEvolved(inputState.value.ogId),
    ]);
    checkBear.value.isEligible = !bearMinted;
    checkEvo.value.isEligible = !isEvoMinted;
  } catch (error) {
    checkBear.value.isEligible = undefined;
    checkEvo.value.isEligible = undefined;
    console.error(error);
    inputState.value.error = error.message;
  } finally {
    inputState.value.loading = false;
  }
};
</script>
