<template>
  <nav class="claim-botb__nav">
    <ul
      v-if="functionalRow"
      class="claim-botb__nav__rows"
    >
      <li
        v-for="button in rowsButtons"
        :key="button.id"
      >
        <button
          :class="button.selected && 'selected'"
          @click="button.click"
        >
          {{ button.name }}
        </button>
      </li>
    </ul>
    <ul v-else>
      <li
        v-for="button in buttons"
        :key="button.id"
      >
        <button
          :class="button.selected && 'selected'"
          @click="button.click"
        >
          {{ button.name }}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { computed, defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    selectedTab: {
      type: String,
      required: true,
    },
    handleChangeTab: {
      type: Function,
      required: true,
    },
    functionalRow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const buttons = computed(() => {
      return [
        {
          id: 0,
          name: "Claimable",
          selected: props.selectedTab === "claimable",
          click: () => props.handleChangeTab("claimable"),
        },
        {
          id: 1,
          name: "BOTB OG",
          selected: props.selectedTab === "ogTokens",
          click: () => props.handleChangeTab("ogTokens"),
        },
        {
          id: 2,
          name: "BOTB EVO",
          selected: props.selectedTab === "evoTokens",
          click: () => props.handleChangeTab("evoTokens"),
        },
        {
          id: 3,
          name: "BOTB BEARS",
          selected: props.selectedTab === "bearTokens",
          click: () => props.handleChangeTab("bearTokens"),
        },
      ];
    });
    const rowsButtons = computed(() => [
      {
        id: 0,
        name: "BOTB OG",
        selected: props.selectedTab === "ogTokens",
        click: () => props.handleChangeTab("ogTokens"),
      },
      {
        id: 1,
        name: "BOTB EVO",
        selected: props.selectedTab === "evoTokens",
        click: () => props.handleChangeTab("evoTokens"),
      },
      {
        id: 2,
        name: "BOTB BEARS",
        selected: props.selectedTab === "bearTokens",
        click: () => props.handleChangeTab("bearTokens"),
      },
      {
        id: 3,
        name: "Unvaultable",
        selected: props.selectedTab === "claimable",
        click: () => props.handleChangeTab("claimable"),
      },
      {
        id: 4,
        name: "Evolvable",
        selected: props.selectedTab === "evolvable",
        click: () => props.handleChangeTab("evolvable"),
      },
      {
        id: 5,
        name: "Mintable",
        selected: props.selectedTab === "mintable",
        click: () => props.handleChangeTab("mintable"),
      },
    ]);

    return {
      buttons,
      rowsButtons,
    };
  },
});
</script>
